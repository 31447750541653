import React from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import PrivacyPolicy from "./PrivacyPolicy";
import TermsAndConditions from "./TermsAndConditions";
import Welcome from "./Welcome";
import Footer from "./Footer";
import Header from "./Header";
import DeleteUserAccountPolicy from "./DeleteUserAccountPolicy";


// Pages
function App() {
  return (
    <div>

      <Router>
        <Header />

        <Routes>
          <Route path="/" element={<Welcome />} />
          <Route path="/terms-and-conditions" element={<TermsAndConditions />} />
          <Route path="/privacy-policy" element={<PrivacyPolicy />} />
          <Route path="/user-account-policy" element={<DeleteUserAccountPolicy />} />
        </Routes>

        <Footer />
      </Router>
    </div>
  );
}

export default App;

import React from "react";
import "./assets/DeleteUserAccountPolicy.css"; // Import the stylesheet

const DeleteUserAccountPolicy = () => {
  return (
    <div className="delete-account-page">
      {/* Banner */}
      <div className="banner">
        <h1>Delete User Account Policy</h1>
        <p>Effective Date: 01/01/2025</p>
      </div>

      {/* Content */}
      <div className="policy-content">
        <section>
          <h2>1. Introduction</h2>
          <p>
            At Fit-Mate24, we respect your right to control your personal data.
            If you choose to delete your account, this policy outlines the
            steps, implications, and conditions of account deletion.
          </p>
        </section>

        <section>
          <h2>2. How to Request Account Deletion</h2>
          <p>Users can request account deletion through the following methods:</p>
          <ul>
            <li>
              <strong>In-App Option:</strong> Navigate to <em>Settings &gt; Account &gt; Delete Account</em> 
              and follow the on-screen instructions.
            </li>
            <li>
              <strong>Email Request:</strong> Send an email to 
              <a href="mailto:info@fit-mate24.com"> info@fit-mate24.com</a> 
              from your registered email address, requesting account deletion.
            </li>
          </ul>
        </section>

        <section>
          <h2>3. What Happens When You Delete Your Account?</h2>
          <p>Once you confirm the deletion of your account:</p>
          <ul>
            <li>Your personal information (name, email, profile data) will be permanently removed.</li>
            <li>Any saved preferences, settings, and history will be erased.</li>
            <li>
              If applicable, active subscriptions will be canceled, but charges already incurred will not be refunded.
            </li>
            <li>
              Any content or posts you have shared may still be visible if they are part of group discussions or shared with other users.
            </li>
          </ul>
        </section>

        <section>
          <h2>4. Processing Time</h2>
          <p>
            Account deletion requests are typically processed within <strong>10 days</strong> from the date of submission.
            You will receive a confirmation email once your account has been successfully deleted.
          </p>
        </section>

        <section>
          <h2>5. Non-Refundable Payments</h2>
          <ul>
            <li>Payments made before the deletion request are non-refundable.</li>
            <li>
              If you have an active subscription, you must cancel it before deleting your account to avoid future charges.
            </li>
          </ul>
        </section>

        <section>
          <h2>6. Exceptions & Limitations</h2>
          <p>Your account may not be deleted immediately if:</p>
          <ul>
            <li>There are outstanding payments or pending transactions.</li>
            <li>Your account is under investigation for violations of our Terms of Service.</li>
            <li>Legal, regulatory, or compliance obligations require us to retain certain data.</li>
          </ul>
        </section>

        <section>
          <h2>7. Data Retention Policy</h2>
          <p>
            Some non-personal and anonymized data may be retained for analytical purposes.
            If required by law, we may store transaction records for a legally defined period.
          </p>
        </section>

        <section>
          <h2>8. Reinstating a Deleted Account</h2>
          <p>
            Once an account is deleted, it <strong>cannot</strong> be restored.
            If you wish to use our services again, you will need to create a new account.
          </p>
        </section>

        <section>
          <h2>9. Contact & Support</h2>
          <p>
            If you have any questions or need assistance with deleting your account, please contact us at:
          </p>
          <ul>
            <li>Email: <a href="mailto:info@fit-mate24.com">info@fit-mate24.com</a></li>
          </ul>
        </section>

        <section style={{ marginTop: "30px", textAlign: "center" }}>
          <p>
            By using Fit-Mate24, you acknowledge and agree to the terms outlined in this Account Deletion Policy.
            We are committed to ensuring your privacy and security throughout the process.
          </p>
        </section>
      </div>
    </div>
  );
};

export default DeleteUserAccountPolicy;
